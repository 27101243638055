import React from "react";
import { Avatar, Tooltip, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from "react-redux";
import { UserService } from "../services/UserService";
import { formatToShortDateTime, getAltLetters, getColorFromName } from "../helpers/Common";
import { AddAgentToAgents } from "../features/app/InteractionSlice";

import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
    },
    icon: {
        paddingRight: theme.spacing(1),
        '& .MuiAvatar-root': {
            fontSize: '0.7rem'
        }
    },
    content: {
        '& > *': {
            lineHeight: 1.2,
            textAlign: 'left',
        },
        '& > :first-of-type': {
            paddingBottom: theme.spacing(0),
        },
    },
    smallFont: {
        fontSize: 12
    },
    largeFont: {
        fontSize: 16
    },
    lastLogin: {
        fontSize: '0.66rem',
        marginTop: 6
    },
}));

export default function PersonInfo({ id, showImageOnly = false, large = false, userLastLogin = null, showNameOnly = false, popper=false, setAnchorEl=null  }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const styles = {
        small: {
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
        },
        large: {
            width: theme.spacing(6.5),
            height: theme.spacing(6.5),
        },
    }

    const [photo, setPhoto] = React.useState("");
    const { agents } = useSelector(state => state.interaction);

    let selectedAgent = agents[id];

    React.useEffect(() => {
        if (selectedAgent === undefined && id !== undefined) {
            (async () => {
                let result = await UserService.person(id);
                if (result.success) {
                    let agentData = {
                        id: result?.data?.id,
                        data:{
                            name: result?.data?.name
                        }
                    }
                    dispatch(AddAgentToAgents(agentData));

                    if (!showNameOnly) {
                        let newPhoto = await UserService.getPhoto(id);
                        let urlCreator = window.URL || window.webkitURL;
                        let imageUrl = urlCreator.createObjectURL(newPhoto);
                        setPhoto(`${imageUrl}`)
                    }
                }
            })();
        } else if ((selectedAgent ?? false) && !showNameOnly) {
            (async () => {
                let urlCreator = window.URL || window.webkitURL;
                let newPhoto = await UserService.getPhoto(id);
                let imageUrl = urlCreator.createObjectURL(newPhoto);
                setPhoto(`${imageUrl}`)
            })()
        }
    }, [id, dispatch, selectedAgent])

    const openPopper = (event) => {
        if(popper){
            setAnchorEl(event.currentTarget);
        }
    }

    return (
        <div onClick={openPopper} style={{cursor: popper? 'pointer' : ''}}>
            {
                showNameOnly === false ?
                    <div className={classes.root}>
                        <div className={classes.icon}>
                            <Tooltip title={selectedAgent !== undefined ? (showImageOnly ? selectedAgent?.name : '') : t('Unknown')} arrow>
                                <Avatar
                                    style={{ backgroundColor: selectedAgent !== undefined ? getColorFromName(selectedAgent?.name) : "#ff1aff" }}
                                    src={photo !== "" ? photo : '/'}
                                    sx={large === false ? styles.small : styles.large}
                                >
                                    <span className={large === false ? classes.smallFont : classes.largeFont}>{selectedAgent !== undefined ? getAltLetters(selectedAgent?.name) : "UN"}</span>
                                </Avatar>
                            </Tooltip>
                        </div>
                        {
                            !showImageOnly &&
                            <div className={classes.content}>
                                <Typography component={'span'} variant="body2" color="textPrimary">{selectedAgent !== undefined ? selectedAgent?.name : t("Unknown")}</Typography>
                                {userLastLogin !== null && <div className={classes.lastLogin}>{t("Last Login")} : {formatToShortDateTime(userLastLogin)}</div>}
                            </div>
                        }
                    </div> :
                    <Typography variant="body2">{selectedAgent !== undefined && selectedAgent?.name !== undefined ? selectedAgent?.name : ""}</Typography>
            }
        </div>
    )
}