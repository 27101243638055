import React from 'react';
import { Grid, IconButton } from '@mui/material';
import GroupBox from '../../components/GroupBox';
import CallOptions from './CallOptions';
import TimeLine from './TimeLine';
import { CommunicationType } from './../../helpers/Constant';
import PreviuosCalls from './PreviousCalls';
import ContactInfo from './ContactInfo';
import WrapupNotes from "./WrapupNotes";
import { useSelector, useDispatch } from 'react-redux';
import { CallService } from '../../services/CallService';
import { checkUserPermission, HandleServerResponse, isEmptyObject } from '../../helpers/Common';
import moment from "moment";
import { useTranslation } from 'react-i18next';

const updateNotePermissions = ['call.historyupdate']

const CallDetails = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const updateNoteAccess = checkUserPermission(user.permissions, updateNotePermissions);
    const [updateNotePermission] = React.useState(updateNoteAccess);

    const [call, setCall] = React.useState({});
    const [users, setUsers] = React.useState([]);
    const [noteDataLoaded, setNoteDataLoaded] = React.useState(false);
    const [reloadPrevious, setReloadPrevious] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [currentQueue, setCurrentQueue] = React.useState("Unknown");
    const [contact, setContact] = React.useState(null);
    const [timeline, setTimeline] = React.useState({ queueName: currentQueue, type: props.type, data: {} });
    const [current, setCurrent] = React.useState(true);
    const [wrapupNote, setWrapupNote] = React.useState({ note: '', outcomeId: '', forms: null });
    const [disableNote, setDisableNote] = React.useState(false);
    const [wrapupData, setWrapupData] = React.useState({});
    const [selectedIntegrationData, setSelectedIntegrationData] = React.useState(null);
    const { t } = useTranslation();

    React.useEffect(() => {
        setLoading(true);
        (async () => {
            if (props.type === CommunicationType.call) {
                let result = await CallService.GetUserCallRecord(props.data.id);
                HandleServerResponse(result, "", dispatch);
                if (result.success) {
                    setCall(result.data);
                    setContact({
                        source: result.data.source,
                        queueName: result.data.queue,
                        email: "",
                        userInput: result.data?.userInput,
                        callStatus: props.type,
                        chainId: result.data.chainId,
                        callFlow: result.data.callFlowName,
                        queueId: result.data.queueId
                    });
                    setCallTimeLine(result.data);
                    await setCallWrapup(result.data.queueId, { note: result.data.note, outcome: result.data.outcome, outcomeId: result.data.outcomeId, forms: result.data.forms });
                }

            } else {
                const queue = user.queues?.find(x => x.queueId === props.data.queueId)?.name ?? "Unknown";
                setCurrentQueue(queue)
                setCall(props.data);
                setContact({
                    source: props.data.source,
                    queueName: queue,
                    email: "",
                    userInput: props.data?.userInput,
                    callStatus: props.type,
                    chainId: props.data.chainId,
                    callFlow: props.data.callFlow,
                });
                setCurrentTimeLine(props.data);
                setCurrentWrapup(props.data.queueId, props.data.refId ?? props.data.id);
            }
        })();

        setLoading(false);
    }, [props.type, props?.data?.holdMusic]);


    React.useEffect(() => {
        EvalDisableNote();
    }, [call]);

    const setCallTimeLine = (data) => {
        setTimeline({
            queueName: data.queue,
            type: props.type,
            data: {
                callStart: data.start,
                waitingStart: data.waitingStart,
                activeStart: data.activeStart,
                end: data.end,
                userId: data?.users,
                source: data.source,
                status: data.status,
                chainId: data.chainId,
                direction: data.direction,
            }
        });
    }

    const setCurrentTimeLine = (data) => {
        setTimeline({
            queueName: currentQueue,
            type: props.type,
            data: {
                callStart: data.callStart,
                waitingStart: data.waitingStart,
                activeStart: data.activeStart,
                end: data.end,
                wrapupend: data.wrapupend !== undefined ? data.wrapupend : undefined,
                userId: data?.userId,
                source: data.source,
                status: data.status,
                chainId: data.chainId,
                direction: data.direction,
            }
        });
    }

    const setCallWrapup = async (queueId, data) => {
        await loadWrapupData(queueId);
        setWrapupNote(data);
    }

    const setCurrentWrapup = async (queueId, refId) => {
        await loadWrapupData(queueId);

        if (!noteDataLoaded) {
            const data = await loadNoteData(refId);
            if (data !== null) {
                setWrapupNote(data);
                setCall(x => ({ ...x, note: data.note, outcomeId: data.outcomeId, forms: data.forms }));
            }
            setNoteDataLoaded(true);
        }

        EvalDisableNote();
    }

    const changePreviousCallDetails = async (data) => {
        setCurrent(() => {
            if(data?.chainId === call?.chainId){
                return true;
            }
            return false;
        });
        setTimeline(data.timeline);
        await setCallWrapup(data.queueId, { note: data.note, outcomeId: data.outcome.id, forms: data.forms, chainId: data?.chainId, referenceId: data?.referenceId });
    }

    const handleCurrentCall = () => {
        setCurrent(true);
        (async () => {
            if (props.type === CommunicationType.call) {
                setCallTimeLine(call);
            } else {
                setCurrentTimeLine(call);
            }
            await setCallWrapup(call.queueId, { note: call.note, outcomeId: call.outcomeId, forms: call.forms });
        })();

    }

    const moreThan15Munites = (endTime) => {
        let curentDateTime = new Date().getTime()
        var callEndTime = moment(new Date(endTime).getTime()).add(15, 'm').toDate();
        if (curentDateTime < callEndTime) {
            return false
        } else {
            return true;
        }
    }

    const EvalDisableNote = () => {
        if (props.type === CommunicationType.invitecall) {
            setDisableNote(true);
        } else if (props.type === CommunicationType.call) {
            //check whether logged user is in active users or not
            if (call.users) {
                let findUser = call.users.find(x => x.id === user.userId);

                if (findUser === undefined) {
                    setDisableNote(true);
                    return;
                }
            }

            //check permission 
            if (updateNotePermission) {
                setDisableNote(false);
                return;
            }

            //check the 15 munites login
            if (call.end) {
                setDisableNote(moreThan15Munites(call.end));
                return;
            }

            setDisableNote(true);
        } else if (props.type === CommunicationType.completecall) {
            //check permission 
            if (updateNotePermission) {
                setDisableNote(false);
                return;
            }

            //check the 15 munites login
            if (call.end) {
                setDisableNote(moreThan15Munites(call.end));
                return;
            }

            setDisableNote(true);
        }
        else {
            setDisableNote(false);
        }
    }

    const loadWrapupData = async (queueId) => {
        if (queueId === null) {
            return;
        }

        if (queueId in wrapupData) {
            return;
        }

        const result = await CallService.GetOutcomesByQueue(queueId, false);
        HandleServerResponse(result, "", dispatch);
        if (result.success) {
            setWrapupData(x => ({ ...x, [queueId]: { ...result.data } }));
        }
    }

    const loadNoteData = async (refId) => {
        const result = await CallService.GetCallHistoryByChainId(refId, false);
        HandleServerResponse(result, "", dispatch);

        if (result.success && result.data !== null) {
            return {
                note: result.data.note,
                outcomeId: result.data.outcome,
                forms: result.data.forms
            }
        }

        return null;
    }

    const updateNoteData = (data) => {
        setCall(x => ({ ...x, note: data.note, outcomeId: data.outcomeId, forms: data.forms }));
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item sm={12} md={6} xs={12}>
                    {!isEmptyObject(call) && (props.type === CommunicationType.invitecall || props.type === CommunicationType.waitingcall || props.type === CommunicationType.activecall) &&
                        <GroupBox flex center gutter showDivider={false}>
                            {loading ?
                                <div>Loading...</div> :
                                <CallOptions
                                    users={users}
                                    type={props.type}
                                    queueId={call.queueId}
                                    phone={call.source}
                                    chainId={call.chainId}
                                    activeStart={call.activeStart}
                                    referenceId={call.refId}
                                    callId={call?.callId}
                                    waitingId={call?.waitingId}
                                    holdMusic={call?.holdMusic ?? false}
                                />}
                        </GroupBox>
                    }
                    <GroupBox title={t("Contact Information")} gutter>
                        {loading ?
                            <div>Loading...</div> :
                            (Boolean(call?.chainId) && <ContactInfo
                                {...contact}
                                referenceId={call.refId !== undefined ? call.refId : call.id}
                                chainId={call.chainId}
                                setSelectedIntegrationData={setSelectedIntegrationData}
                                queueId={call.queueId}
                            />)}
                    </GroupBox>
                    <GroupBox smallBodyPadding={true} title={t("Previous Calls")} actionTitle={current ? '' : 'Reset View'} groupboxAction={handleCurrentCall}>
                        {call && <PreviuosCalls
                            reload={reloadPrevious}
                            changeCallDetails={changePreviousCallDetails}
                            source={call.source}
                            selectedCallId={props.id}
                        />}
                    </GroupBox>
                </Grid>
                <Grid item sm={12} md={6} xs={12}>
                    <GroupBox title={t("Timeline")} gutter>
                        {loading ? <div>Loading...</div> : <TimeLine {...timeline} />}
                    </GroupBox>
                    {loading ?
                        <div>Loading...</div> :
                        (call && (<WrapupNotes
                            disabled={!(current && !disableNote)}
                            type={props.type}
                            chainId={call.chainId}
                            referenceId={call.refId !== undefined ? call.refId : call.id}
                            dataObj={wrapupNote}
                            outcomeValues={wrapupData[call.queueId]?.outcomes ?? []}
                            customNoteFields={wrapupData[call.queueId]?.formElements ?? []}
                            integrationData={selectedIntegrationData}
                            handlePreviousCallReload={() => setReloadPrevious(!reloadPrevious)}
                            updateNoteData={updateNoteData}
                            JiraConnector={wrapupData[call.queueId]?.jiraConnectorData}
                            current={current}
                        />))
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default CallDetails;