import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Link, Typography } from '@mui/material';
import { useDispatch } from "react-redux"
import { CallMade, CallReceived } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { extractTimeFromSeconds, formatToLongDateTime, HandleServerResponse } from '../../helpers/Common';
import { CallService } from '../../services/CallService';
import { CallDirection, CommunicationType } from "../../helpers/Constant";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dataGrid: {
        border: 0,
        minHeight: 300,
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.background.default
        },
    },
    answredIncomingIcon: {
        color: theme.palette.success.main,
    },
    answredOutgoingIcon: {
        color: theme.palette.info.main,
    }
}));

export default function PreviuosCalls(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    const [page, setPage] = React.useState(1);
    const [sort, setSort] = React.useState({ field: "Start", sort: "desc" });
    const [pageSize] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [callList, setCallList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const handlePageChange = (params) => {
        setPage(params + 1);
    };

    const handleSorting = (params) => {
        if (params.length > 0) {
            setSort(params[0]);
        }
    }

    const getIcon = (direction) => {
        switch (direction) {
            case CallDirection.Incoming:
                return <CallReceived fontSize="small" className={classes.answredIncomingIcon} /> // incoming answered
            case CallDirection.Outgoing:
                return <CallMade fontSize="small" className={classes.answredOutgoingIcon} /> // outgoing answered
            default:
                break;
        }
    }

    const openCallTab = (data) => {
        let selectedCallInfo = {
            timeline: {
                queueName: data.queue,
                type: CommunicationType.call,
                data: {
                    ...data.timeline,
                    userId: data?.users,
                    source: props.source,
                    status: data?.status,
                    direction: data?.direction,
                }
            },
            note: data.note.note,
            outcome: { id: data.outcomeId, value: data.outcome },
            forms: data.note.forms,
            queueId: data.queueId,
            chainId: data.chainId,
            referenceId: data.id,
        }

        props.changeCallDetails(selectedCallInfo)
    }

    React.useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);

            let params = { Id: props.source, PageNumber: page, RowCount: pageSize, SortFiled: sort.field, SortOrder: sort.sort, SearchValue: "" }
            let result = await CallService.GetCalleCallHitory(params);

            HandleServerResponse(result, "", dispatch);
            if (result.success && active) {
                setTotalRecords(result.pagination.totalRecords);
                setCallList(result.data);
            }

            if (!active) { return; }

            setLoading(false);
        })();

        return () => { active = false; };
    }, [page, sort, pageSize, props.reload, props.source, dispatch]);

    const colums = [
        {
            field: "queue",
            headerName: t('Queue'),
            flex: 2,
            renderCell: (params) => (
                <React.Fragment>
                    {getIcon(params.row.direction)}
                    <Typography variant="body2" >
                        <Link onClick={() => openCallTab(params.row)} href="#">
                            {params.row.queue}
                        </Link>
                    </Typography>
                </React.Fragment>
            ),
        },
        {
            field: "Start",
            headerName: t('Time'),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="body2" >{formatToLongDateTime(params.row.start)}</Typography>
            ),
        },
        {
            field: "Duration",
            headerName: t('Duration'),
            flex: 1,
            renderCell: (params) => (
                <Typography variant="body2" >{extractTimeFromSeconds(params.row.duration)}</Typography>
            ),
        },
        {
            field: "Outcome",
            headerName: t('Outcome'),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="body2" >{params.row.outcome}</Typography>
            ),
        },
    ]

    return (
        <DataGrid
            rows={callList}
            columns={colums}
            sortingMode="server"
            onSortModelChange={handleSorting}
            pageSize={pageSize}
            rowCount={totalRecords}
            pagination
            paginationMode="server"
            onPageChange={handlePageChange}
            loading={loading}
            rowsPerPageOptions={[]}
            hideFooterSelectedRowCount={true}
            className={classes.dataGrid}
            density="compact"
            autoHeight
            disableColumnMenu
        />
    );
}