import axios from "axios";

const LoadConversations = async () => {
    const { data } = await axios.get('api/conversation/conversations').then(result => result);
    return data;
};

const LoadConversation = async (values) => {
    const { data } = await axios.get('api/conversation', { params: values }).then(result => result);
    return data;
};

const LoadMessage = async (values) => {
    const { data } = await axios.get('api/conversation/message', { params: values }).then(result => result);
    return data;
};

const HandleConversation = async (values) => {
    const { data } = await axios.post('api/conversation/operation', values).then(result => result);
    return data;
};

const ReplyEmail = async (values) => {
    const { data } = await axios.post('api/conversation/emailoperation', values, { headers: { 'Content-Type': 'multipart/form-data' } }).then(result => result);
    return data;
};

const GetAutoResponse = async (values) => {
    const { data } = await axios.post('api/conversation/autoresponse', values).then(result => result);
    return data;
};

const AiAssistant = async (values) => {
    const { data } = await axios.post('api/conversation/aioperation', values).then(result => result);
    return data;
};

const GetMainConversationHistory = async (values) => {
    const { data } = await axios.get(`/api/conversation/search`,{params: values}).then(result => result);
    return data;
};

const GetGetFilterReferences = async () => {
    const { data } = await axios.get(`/api/conversation/references`).then(result => result);
    return data;
};

const DownloadAttachment = async (values) => {
    const { data } = await axios.get('api/conversation/attachment', { params: values, responseType: 'blob' }).then(result => result);
    return data;
};

export const ConversationService = {
    LoadConversations,
    LoadConversation,
    LoadMessage,
    HandleConversation,
    ReplyEmail,
    GetAutoResponse,
    AiAssistant,
    GetMainConversationHistory,
    GetGetFilterReferences,
    DownloadAttachment
};