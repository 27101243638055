import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ChatService } from '../../../services/ChatService';
import { HandleServerResponse } from '../../../helpers/Common';
import { removeActiveChat, removeWaitingChat } from '../ChatSlice';
import { ChatStatus, ConversationType } from '../../../helpers/Constant';
import { removeConversation } from '../../conversation/ConversationSlice';

const useStyles = makeStyles(theme => ({
    modalWidth: {
        width: 500
    },
    smallModal: {
        width: 250
    },
}));

export default function TransferChatDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { open, type, chatId, chatStatus, queueId, searchable, small=false } = props;
    const [isItemSelected, setIsItemSelected] = useState(true)
    const [selectedItem, setSelectedItem] = useState({id:'', name:''});
    const [searchItems, setSearchItems] = useState([]);
    const [queues, setqueues] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const styles = {
        actions: {
            margin: theme.spacing(1),
        },
    }

    React.useEffect(() => {
        if(type === "QueueTransfer"){
            LoadQueues();
        }
    }, [])

    const LoadQueues = async () => {
        let active = true;

        (async () => {
            setLoading(true);
            //load queues excluding the current queue from the API
            let queueList = await ChatService.Queues(queueId);
            HandleServerResponse(queueList, "", dispatch);
            if (queueList.success && active) {
                setqueues(queueList.data)
            }

            if (!active) { return; }
            setLoading(false);
        })();
        return () => { active = false; };
    }

	const handleTransfer = async () => {
        if (selectedItem === '' || selectedItem === undefined) {
            setIsItemSelected(false)
        } else {
            if (type === "AgentTransfer"){
                let result = await ChatService.TransferToAgent(chatId, selectedItem.id);
                HandleServerResponse(result, t("Active Chat transfer request to user was sent successfully."), dispatch);
                if(result.success){
                    let prop = {
                        id: chatId,
                        type: ConversationType.Chat,
                    }
                    dispatch(removeConversation(prop));
                    props.handleClose()
                }
                else{
                    HandleServerResponse(result, t("Active Chat is already offered to the same user"), dispatch);
                }

            } else if(type ==="QueueTransfer"){
                let params = { ChatId: chatId, QueueId: selectedItem.id, QueueName: selectedItem.name };

                let result = await ChatService.TransferToQueue(params);
                HandleServerResponse(result, t("Chat transferred to queue successfully."), dispatch); 

                if(result.success){
                    if(chatStatus === ChatStatus.Active){
                        let prop = {
                            id: chatId,
                            type: ConversationType.Chat,
                        }
                        dispatch(removeConversation(prop));
                    }
                }

                props.handleClose()              
            }
        }
    }

    const handleItemChange = (event, value) => {
        event.persist();
        if(value === null){
            setSelectedItem('');
            setIsItemSelected(false);
        }else{
            setSelectedItem({id: value.id, name: value.name});
            setIsItemSelected(true)
        }
    }

    const handleSearchTextChange = async (event, value) => {
        if(value !== undefined && value.length >= 3){
            setLoading(true);
            let params = { QueueId: queueId, Keyword: value };
            let response = await ChatService.Users(params);
            if (Array.isArray(response?.data)) {
                setSearchItems(response.data);
            }
            setLoading(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{t(`Select ${type === "QueueTransfer" ? "a queue" : "a user"} to transfer`)}</DialogTitle>
            <DialogContent>
                <div className={small ? classes.smallModal : classes.modalWidth}>
                    {searchable && <Autocomplete
                        options={searchItems}
                        onChange={handleItemChange}
                        onInputChange={handleSearchTextChange}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t("Agent List")}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }} />}
                    />}
                    {(!searchable && !loading) && <Autocomplete
                        options={queues}
                        getOptionLabel={(option) => option.name}
                        onChange={handleItemChange}
                        renderInput={(params) => <TextField {...params} label={type === "QueueTransfer" ? t("Queue List") : t("User List")} variant="outlined" />}
                    />}
                </div>
                {!isItemSelected && <Typography color="error" variant="body2">{t(`Please select ${type === 'QueueTransfer' ? 'a queue' : 'a user'} to transfer this chat`)}</Typography>}
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button onClick={handleTransfer} variant="contained" color="primary" disableElevation>
                    {t("Transfer")}
                </Button>
                <Button onClick={props.handleClose} variant="contained" color="grey" disableElevation>
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}