import React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton, Badge, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const ItemSkeleton = () => {
    const { user: { userId } } = useSelector((state) => state.auth);

    const Container = styled('div')(({ theme }) => ({
        minHeight: '110px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '8px',
        border: '1px solid #ddd',
        margin: theme.spacing(0, 0, 1, 0),
        transition: 'background-color 0.3s',
        borderRadius: '4px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer'
        },
    }));

    const Header = styled('div')(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 1, 0),
        justifyContent: 'space-between'
    }));

    const HeaderContentRight = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center'
    }));

    const HeaderContentLeft = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
    }));

    const NameSkeleton = styled(Skeleton)(({ theme }) => ({
        marginLeft: theme.spacing(1),
        fontWeight: 'bold',
        width: '100px'
    }));

    const SubjectSkeleton = styled(Skeleton)(({ theme }) => ({
        width: '80%',
    }));

    const IntentSkeleton = styled(Skeleton)(({ theme }) => ({
        marginBottom: theme.spacing(1),
        width: '60%'
    }));

    const AssignButtonSkeleton = styled(Skeleton)(({ theme }) => ({
        marginLeft: theme.spacing(2),
        height: '24px',
        width: '80px'
    }));

    const ChipsContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
    }));

    const ChipSkeleton = styled(Skeleton)(({ theme }) => ({
        height: '22px',
        width: '60px',
        borderRadius: '8px'
    }));

    const MailIconSkeleton = styled(Skeleton)(({ theme }) => ({
        height: '22px',
        width: '22px',
        borderRadius: '8px',
    }));

    return (
        <Container>
            <Header>
                <HeaderContentRight>
                    <Badge badgeContent={0} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <MailIconSkeleton variant="circular" />
                    </Badge>
                    <NameSkeleton variant="text" />
                </HeaderContentRight>
                <HeaderContentLeft>
                    <Skeleton variant="text" width="100px" />
                    <AssignButtonSkeleton variant="rectangular" />
                </HeaderContentLeft>
            </Header>
            <Tooltip title="Loading..." arrow>
                <SubjectSkeleton variant="text" />
            </Tooltip>
            <Tooltip title="Loading..." arrow>
                <IntentSkeleton variant="text" />
            </Tooltip>
            <ChipsContainer>
                <ChipSkeleton variant="rectangular" />
                <ChipSkeleton variant="rectangular" />
            </ChipsContainer>
        </Container>
    );
}

const ConversationListSkeleton = () => {
    return (
        <React.Fragment>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
        </React.Fragment>
    )
}

export default ConversationListSkeleton
