import React from "react";
import Property from "../view/SummaryProperty";
import Priority from "../components/Priority";
import { Link, Rating, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";

const AiSummaryInfo = () => {
    const TimelineField = styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        marginBottom: 4
    }));

    const { intent, priority, sentiment, timelineHighlight } = useSelector(state => state.conversation.current);

    return (
        <>
            <Property name={"Intent"} value={intent} />
            <Property name="Priority">
                <Priority priority={priority} />
            </Property>
            <Property name="Sentiment" isCentre={true}>
                <Rating name="read-only" value={sentiment} readOnly />
            </Property>
            <div>
                <TimelineField variant="body2" color="textSecondary">Timeline Highlight:</TimelineField>
                <AiTimeline items={timelineHighlight} />
            </div>
        </>
    )
}

const AiTimeline = ({ items = [] }) => {
    const [timeline, setTimeline] = React.useState(() => ({
        items: [...items].slice(0, 3),
        more: true
    }));

    const Root = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'baseline'
    }));
    const Dot = styled('div')(({ theme }) => ({
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: grey[500],
        marginRight: 8
    }));

    const handleTimeline = (e) => {
        e.preventDefault();

        setTimeline((prev) => {
            let list = [...items]
            if (!prev.more) {
                list = list.slice(0, 3)
            }
            return {
                items: list,
                more: !prev.more
            }
        })
    }

    if (items.length === 0)
        return <></>

    return (
        <>
            {
                timeline.items.map((item, index) => {
                    return <Root key={index}>
                        <Dot />
                        <div>{item}</div>
                    </Root>
                })
            }
            {items.length > 3 &&
                <Link onClick={(e) => handleTimeline(e)} href="#">{`Show ${timeline.more ? 'more' : 'less'}`}</Link>
            }
        </>
    )
}


export default AiSummaryInfo;