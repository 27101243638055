
const UserStatus = {
    available: "Available",
    inaCall: "InaCall",
    wrapUp: "WrapUp",
    offline: "Offline",
    workOffline: "WorkOffline",
    onBreak: "OnBreak",
    onBreakCustom1: "OnBreakCustom1",
    onBreakCustom2: "OnBreakCustom2",
    workOfflineCustom1: "WorkOfflineCustom1",
    workOfflineCustom2: "WorkOfflineCustom2",

}

const CallStatus = {
    ivr: "ivr",
    waiting: "waiting",
    waitingupdate: "waitingupdate",
    active: "active",
    update: "update",
    complete: "complete",

    abandoned: "abandoned",
    decline: "decline",
    wrapupstart: "wrapupstart",
    wrapupend: "wrapupend",
    ringingstart: "ringingstart",
    ringingend: "ringingend",

    //To handle call completed calls
    completed: "Completed",
    shortcall: "ShortCall",
    //transferred calls
    transferred: "Transferred",
}

const CommunicationType = {
    home: "home",
    setting: "setting",
    agentDashboard: "agentDashboard",
    callDashboard: "callDashboard",
    callHistory: "callHistory",
    conversationHistory: "conversationHistory",
    invitecall: "invitecall", //Allocated call of login user
    activecall: "activecall", //Active call of the login user
    waitingcall: "waitingcall", //Waiting call which has bot been allocated to any user yet
    completecall: "completecall", //Completed call of the login user or active call of another user
    wrapupend: "wrapupend",//Complete the wrapup end action
    call: "call", //History call record
    report: "report", // reports
    attendentConsole: "attendentConsole",
    auditTrail: "auditTrail",
    conversations: "conversations"
}

const QueueProperty = {
    waitingCallCount: "waitingCalls",
    activeCallCount: "activeCalls",
    totalAcceptedCalls: "totalCalls",
    abandonedCallCount: "abandonedCalls",
    totalAgents: "totalAgents",
    availableAgents: "availableAgents"
}

const MessageType = {
    Success: "success",
    Error: "error",
    Warning: "warning",
    Info: "info"
}

const UserAvailability = {
    offline: "Offline",
    workOffline: "WorkOffline",
    available: "Available",
    onBreak: "OnBreak",
    onBreakCustom1: "OnBreakCustom1",
    onBreakCustom2: "OnBreakCustom2",
    workOfflineCustom1: "WorkOfflineCustom1",
    workOfflineCustom2: "WorkOfflineCustom2",
}

const CallDirection = {
    Incoming: "Incoming",
    Outgoing: "Outgoing",
}

const InputTypes = {
    Text: 'text',
    Number: 'number',
    NumberTwo: 'number-two',//from-to
    Date: 'date-from-to',
    DropdownSingle: 'dropdown-single-value',
    DropdownMultiple: 'dropdown-multiple-values',
    Password: 'password',
    Switch: 'switch',
    Time: 'time-from-to',
    DateTime: 'datetime-from-to',
}

const JiraInputTypes = {
    Text: 'string',
    Date: 'date',
    DateTime: 'datetime',
    Number: 'number',
    DropdownSingle: 'option',
    Array: "array",
    Priority: "priority"
}

const SettingActions = {
    Created: 'created',
    Updated: 'updated',
    Disabled: 'disabled',
    Enabled: 'enabled',
    Deleted: 'deleted',
}

const AgentAvailability = {
    Available: 'Available',
    AvailableIdle: 'AvailableIdle',
    Away: 'Away',
    BeRightBack: 'BeRightBack',
    Busy: 'Busy',
    BusyIdle: 'BusyIdle',
    DoNotDisturb: 'DoNotDisturb',
    Offline: 'Offline',
    PresenceUnknown: 'PresenceUnknown',
}

const Widgets = {
    Hub: "Hub",
    ToDo: "To-Do",
    Dialpad: "Dialpad",
    ReleaseNote: "Release Note",
    AgentStatus: "Agent Status",
    CallStatus: "Call Status",
}

const SettingExitPrompt = [4];

const ChatParty = {
    Bot: 0,
    Agent: 1,
    Customer: 2
}

const ChannelType = {
    WebChat: "WebChat"
}

const ChatStatus = {
    Pending: 1,
    Waiting: 2,
    Active: 3,
    Completed: 4,
    Declined: 5,
    Abandoned: 6,
    ActiveQueueTransferred: 7,
    ActiveAgentTransferred: 8
}

const ConversationStatus = {
    New: 1,
    Open: 2,
    Completed: 3,
    Declined: 4,
}

const ChatInputTypes = {
    Text: 0,
    List: 1,
    Name: 2,
    Email: 3,
}

const WallboardType = {
    CentrePal: 0,
    PowerBi: 1
}

const SystemKeywordTypes = {
    None: 0,
    Queue: 1,
    Custom: 2,
}

const LicenseType = {
    Voice: 1,
    VoicePlusDigital: 2,
    AttendantConsole: 3,
    AttendantConsolePlusDigital: 4,
    AIExperience: 5,
    ThirdPartyRecordingIntegration: 6,
    PowerBi: 7,
    PowerBiRealTime: 8
}

const FlowType = {
    IVR: 1,
    Survey: 2,
    Email: 3,
}
const EmailType = {
    MailBox: 1,
    Group: 2,
}

const ConversationType = {
    Email: 1,
    Chat: 2
}

const ConversationPriority = {
    None: "None",
    Urgent: "Urgent",
    Important: "Important",
}

const ConversationOperation = {
    Assign: 1,
    Complete: 2,
    TransferToUser: 3,
    TransferToQueue: 4,
    MarkAsSpam: 5
}

const EmailOperation = {
    Create: 1,
    Reply: 2,
    Forward: 3
}

const MessageTemplateType = {
    Sms: 1,
    Email: 2,
}

const ConversationAction = {
    New: 1,
    NewMessage: 2,
    ActiveStart: 3,
    Abandoned: 4,
    TransferToUser: 5,
    TransferToQueue: 6,
    ActiveEnd: 7,
    WrapUpStart: 8,
    WrapUpEnd: 9,
    TransferReject: 10,
    TransferTimeout: 11,
}

module.exports = {
    UserStatus: UserStatus,
    CallStatus: CallStatus,
    QueueProperty: QueueProperty,
    CommunicationType: CommunicationType,
    MessageType: MessageType,
    UserAvailability: UserAvailability,
    CallDirection: CallDirection,
    InputTypes: InputTypes,
    JiraInputTypes: JiraInputTypes,
    SettingActions: SettingActions,
    AgentAvailability: AgentAvailability,
    Widgets: Widgets,
    SettingExitPrompt: SettingExitPrompt,
    ChatParty: ChatParty,
    ChannelType: ChannelType,
    ChatStatus: ChatStatus,
    ChatInputTypes: ChatInputTypes,
    SystemKeywordTypes: SystemKeywordTypes,
    LicenseType: LicenseType,
    WallboardType: WallboardType,
    FlowType: FlowType,
    EmailType: EmailType,
    ConversationStatus: ConversationStatus,
    ConversationPriority: ConversationPriority,
    ConversationOperation: ConversationOperation,
    ConversationType: ConversationType,
    EmailOperation: EmailOperation,
    MessageTemplateType: MessageTemplateType,
    ConversationAction: ConversationAction,
}